import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatrixFundDetailsService } from '../../../Services/matrix-fund-details.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { CheckAuth } from '../../../Services/utility.service';
import { MatrixModel } from 'src/Models/matrixModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { MatrixService } from 'src/Services/matrix.service';
import { UpdatesPageModel } from 'src/Models/updatesPageModel';
import { AppConfig } from '../../../Services/app.config';
import { UpdatesService } from '../../../Services/updatesService';
import { UpdatesModel } from '../../../Models/updatesModel';
import { ToastrService } from 'ngx-toastr';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';

@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: ['./fund-details.component.css']
})
export class FundDetailsComponent implements OnInit {
  tokenKey: string = '';
  statusCode: any;
  loginUser: any;
  listOfState: string = '1';
  listofMatrixFund: MatrixModel[];
  isNoRecord: boolean = false;
  isNoFundFound: boolean = false;
  totalRecordCount: number = -1;
  isloading: boolean = false;

  fundReference: string = '';

  isSidePanelActive: boolean = true;
  sidepanellistOfState: StateMasterModel[];
  chkselectAll: boolean = false;
  isStateRecord: boolean = false;
  selectedStateCounter: number = 0;
  listOfStateId: string = '';
  selectAllID: string[];
  listOfID: string[];

  showState: boolean = false;

  statenamepopup: string;
  isupdatesloading: boolean = false;
  parameterModel = new UpdatesPageModel();
  appModel: any;
  updatesModel: UpdatesModel[] = [];
  totalupdatesRecordsCount: number = -1;
  totalunread: number = 0;
  unreaddata: string = 'unread';

  isShowprogressSpinner: boolean = true;
  blockedDocument: boolean = true;
  drpOtherReuirement: string = '';
  rowStart: string = '<div class="row">';
  rowEnd: string = '</div>';
  baseUrl: string;
  adminUserType: boolean = true;
  oneStatePdfDonwload: boolean = true;
  multiStatePdfDonwload: boolean = false;
  isPageBreak: boolean = false;

  isOuterNoRecord :boolean = false;

  @ViewChild('deletecloseBtn', {static: false}) deletecloseBtn: ElementRef;
  isShowPdfAndPrintButton: boolean = true;
  productId: number = 1;

  stateName :string;
  constructor(private titleService: Title, @Inject('BASE_URL') baseUrl: string, private htmlToPdf: HtmlToPdf, private toastr: ToastrService, private updatesService: UpdatesService, private matrix: MatrixService, private activedRoute: ActivatedRoute, private checkAuth: CheckAuth, private router: Router, private MaxtixFund: MatrixFundDetailsService) {
    this.oneStatePdfDonwload = true;
    this.multiStatePdfDonwload = false;

    if (sessionStorage.getItem('AdminUserType') == "1")
      this.adminUserType = false;

    this.baseUrl = baseUrl;

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    if (sessionStorage.getItem('isAdminOpen') == 'true') {
      this.loginUser = sessionStorage.getItem('AdminUserId');
      this.listOfState = sessionStorage.getItem('StateSetupManageRedirection');
      this.productId = ToolEnum[sessionStorage.getItem('UserLoginTool')];
    }
    else {
      this.loginUser = sessionStorage.getItem('UserId');
      this.listOfState = sessionStorage.getItem('singleStatePreview');
      this.productId = ToolEnum[sessionStorage.getItem('ClientLoginTool')];
    }

    this.listofMatrixFund = [];
    this.totalRecordCount = -1;
    this.isloading = false;
    this.listOfID = [];

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.fundReference = params['id'];
        }
      }
    });

    if(sessionStorage.getItem('updatefunddata'))
      this.fundReference = sessionStorage.getItem('updatefunddata');

    sessionStorage.removeItem('updatefunddata');

    if (this.fundReference != null && this.fundReference != '')
      this.showState = true;

    this.appModel = AppConfig.settings.ConnectionStrings;
    this.titleService.setTitle('Details');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.fundReference != '' && this.fundReference != undefined)
        this.moveOnHmtlID(this.fundReference);
    }, 1000);
  }

  ngOnInit() {
    this.totalunread = +sessionStorage.getItem(this.unreaddata);
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.GetRightPanelStateList();
    this.GetMaxtixFund();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetMaxtixFund() {
    this.isloading = true;
    this.blockDocument();
    this.MaxtixFund.GetMatrixFindByStateID(this.tokenKey, this.loginUser, this.listOfState, this.productId).subscribe(
      data => {
        this.isOuterNoRecord = false;
        this.isNoRecord = false;
        this.listofMatrixFund = data['matrixFund'];
        this.stateName = data['matrixFund'][0].stateName;
        this.isloading = false;
        if (data['matrixFund'] != null) {
          if (data['matrixFund'].length > 0) {
            this.totalRecordCount = data['matrixFund'].length;
          }
          else{
            this.isOuterNoRecord = true;
          }

          this.isNoFundFound = false;
          this.isNoRecord = false;
        }
        else {
          this.selectedStateCounter = 0;
        }

        if (data['matrixFund'] != null) {
          if (data['matrixFund'].length == 0) {
            this.isNoRecord = true;
          }
          else {
            if (data['matrixFund'][0].listOfMatrix.length == 0) {
              this.isNoFundFound = true;
            }
          }
        }
        else
          this.isNoRecord = true;

        this.unblockDocument();
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
      }
    );
  }

  createMyPdf() {
    this.isPageBreak = true;
    this.blockDocument();
    let mainURL = this.baseUrl;

    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreatePDF(this.tokenKey, html, '0',this.productId,this.stateName).
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('StateDetails.pdf', data['url'], data['fileName']);
          this.deletecloseBtn.nativeElement.click();
        }
      },
        error => {
          this.statusCode = error;
          this.unblockDocument();
        }
      );
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  ShowHideSidePanel() {
    this.isSidePanelActive = !this.isSidePanelActive;
  }

  GetRightPanelStateList() {
    this.isStateRecord = false;
    this.selectAllID = [];
    this.listOfID = [];

    this.MaxtixFund.GetMatrixFundState(this.tokenKey, this.loginUser,this.productId).subscribe(
      data => {
        this.isStateRecord = true;
        this.selectedStateCounter = 0;
        this.sidepanellistOfState = data['state'];

        if (this.sidepanellistOfState != null) {
          if (this.sidepanellistOfState.length > 0) {
            this.isShowPdfAndPrintButton = true;
            for (let iCounter = 0; iCounter < this.sidepanellistOfState.length; iCounter++) {
              if (this.sidepanellistOfState[iCounter].stateId == this.listOfState) {
                this.listOfID.push(this.listOfState);
                this.sidepanellistOfState[iCounter].isChecked = true;
              }
              else {
                this.sidepanellistOfState[iCounter].isChecked = false;
              }
              this.selectAllID.push(this.sidepanellistOfState[iCounter].stateId);
            }
          }
        }
        else {
          this.isShowPdfAndPrintButton = false;
        }
        this.selectedStateCounter = 1;
      },
      error => {
        this.statusCode = error;
      }
    );
  }

  checkIfSelected(stateId: any, event: any) {
    this.fundReference = '';
    if (!event.currentTarget.checked) {
      this.listOfID = this.listOfID.filter(m => m != stateId);
    }
    else {
      this.listOfID.push(stateId);
    }
    this.populateSelectedIdData(this.listOfID);
  }

  populateSelectedIdData(listOfID: any) {
    if (this.listOfID != null) {
      let seprator = '';
      if (this.listOfID.length > 0) {
        this.listOfStateId = '';
        this.isShowPdfAndPrintButton = true;
        for (let iCounter = 0; iCounter < this.listOfID.length; iCounter++) {
          this.listOfStateId += seprator + this.listOfID[iCounter];
          seprator = ',';
        }
        this.selectedStateCounter = this.listOfID.length;
        if (this.listOfID.length == 1) {
          this.oneStatePdfDonwload = true;
          this.multiStatePdfDonwload = false;
        }
        else {
          this.oneStatePdfDonwload = false;
          this.multiStatePdfDonwload = true;
        }

      }
      else {
        this.listOfStateId = 'null';
        this.selectedStateCounter = 0;
        this.isShowPdfAndPrintButton = false;
      }
    }
    this.listOfState = this.listOfStateId;
    this.GetMaxtixFund();
  }

  selectAllCheckBox(event: any) {
    this.listOfStateId = '';
    this.listOfID = [];
    if (!event.currentTarget.checked) {
      this.isShowPdfAndPrintButton = false;
      for (let iCounter = 0; iCounter < this.sidepanellistOfState.length; iCounter++) {
        this.sidepanellistOfState[iCounter].isChecked = false;
      }
    }
    else {
      this.isShowPdfAndPrintButton = true;
      this.listOfID = this.selectAllID;
      for (let iCounter = 0; iCounter < this.sidepanellistOfState.length; iCounter++) {
        this.sidepanellistOfState[iCounter].isChecked = true;
      }
    }

    this.populateSelectedIdData(this.listOfID);
  }

  openpopup(stateid, statename) {
    this.statenamepopup = statename;
    this.GetUpdates(stateid);
  }

  GetUpdates(stateid): void {
    this.isupdatesloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.parameterModel.pageSize = this.appModel.PageSize;
    this.parameterModel.pageIndex = '1';
    this.parameterModel.keywordFilter = 'null';
    this.parameterModel.whereCondition = ' StateId=' + stateid;
    this.parameterModel.orderBy = 'UpdateDate desc';
    this.parameterModel.fromupdatesDateFilter = "null";
    this.parameterModel.toupdatesDateFilter = "null";
    this.parameterModel.userID = this.loginUser.toString();
    this.parameterModel.updatetype = "null";
    this.parameterModel.unreadonly = "1";
    this.updatesService.getupdatesfindByUserID(this.tokenKey, this.parameterModel).subscribe(
      data => {
        this.isupdatesloading = false;
        if (data["status"] == "Success") {
          this.updatesModel = data["updates"];
          if (this.updatesModel.length > 0) {
            this.totalupdatesRecordsCount = this.updatesModel.length;
            this.saveNotification(this.updatesModel);
          }
          else {
            this.totalupdatesRecordsCount = 0;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }

  saveNotification(updates) {
    let _updates = updates;
    for (let iCounter = 0; iCounter < _updates.length; iCounter++) {
      _updates[iCounter].createdBy = this.loginUser.toString();
    }
    let count = updates.length;
    this.totalunread = +sessionStorage.getItem(this.unreaddata);
    this.updatesService.addNotification(_updates, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        sessionStorage.setItem(this.unreaddata, ((+this.totalunread) - (+count)).toString());
        this.totalunread = +sessionStorage.getItem(this.unreaddata);
        if (window.document.body.getElementsByClassName('unread')) {
          if (this.totalunread == 0) {
            window.document.body.getElementsByClassName('unread')[0].setAttribute("style", "display:none");
          }
          else
            window.document.body.getElementsByClassName('unread')[0].innerHTML = this.totalunread.toString();
        }
        this.GetMaxtixFund();
      }
      else {
        this.showMessage(data.status, 'Error', 'error');
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
    });
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  onChange($event: any) {
    if ($event == 'Choose a Topic') {
      this.drpOtherReuirement = '';
    }
    else
      this.drpOtherReuirement = $event;
  }

  moveOnHmtlID(fundReference: any) {
    if (this.showState) {
      let top = document.getElementById(fundReference);
      if (top !== null) {
        top.scrollIntoView({ behavior: "smooth", block: "start", inline: 'nearest' });
        top = null;
      }
    }
  }

  ReplaceString(value) {
    return value.replace(/\//g,'-');
  }

}
