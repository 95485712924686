import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Trfa911FTPUploadHistoryModel } from 'src/Models/TRFA_911_FTPUploadHistoryModel';
import { Main911Service } from 'src/Services/main911.service';
import * as FileSaver from 'file-saver';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ToastrService } from 'ngx-toastr';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import { Title } from '@angular/platform-browser';
import { FilterModel } from 'src/Models/filterModel';
import { EmailLoginService } from 'src/Services/emaillogin.service';

@Component({
  selector: 'app-downloadfilesonboarding',
  templateUrl: './downloadfilesonboarding.component.html',
  styleUrls: ['./downloadfilesonboarding.component.css']
})
export class DownloadfilesonboardingComponent implements OnInit {

  userid: number = 0;
  ListHistoryModel: Trfa911FTPUploadHistoryModel[];
  isloading: boolean = true;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  DownloadLink: string;
  tokenKey: any;
  clientInformationId: number;
  priceQuoteId: number;

  quaryValues: string = '';
  filterModel = new FilterModel();

  constructor(private activedRoute: ActivatedRoute,
    private router: Router,
    private main911Service: Main911Service,
    private appActivity: ApplicationActivityService,
    private toastr: ToastrService,
    private PricingQuotesMaster: PricingQuotesService,
    private htmlToPdf: HtmlToPdf,
    private titleService: Title,
    private emailLoginService: EmailLoginService
  ) {
    this.tokenKey = localStorage.getItem('tokenValue');
    this.titleService.setTitle('Quotes And Onboarding Download');
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.quaryValues = params['id'];
          this.filterModel.emailKey = params['id'];
          this.IsValidOnboardingQuoteEmailKey();
        }
        else {
          this.router.navigate(['login/' + this.quaryValues]);
        }
      }
      else {
        this.router.navigate(['login/' + this.quaryValues]);
      }
    });
  }

  IsValidOnboardingQuoteEmailKey() {
    this.blockedDocument = true;
    this.emailLoginService.isValidOnboardingQuoteEmailKey(this.filterModel).subscribe(
      data => {
        this.blockedDocument = false;
        sessionStorage.setItem('emailOnboardingQuoteUId', data.userId);

        if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
          this.clientInformationId = data.clientInformationId;
          this.priceQuoteId = data.priceQuoteId;
          this.downloadOnboardingdetails();
        } 
        else if (data.status == 'Success')
        {
          this.router.navigate(['login/' + this.quaryValues]);
        }
        else if(data.status == 'Expired' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')){
            var Urldata='Access/Denied/'+decodeURI('Quotes-and-Onboarding');  
            this.router.navigate([Urldata]);
        }
        else
          this.router.navigate(['login']);

      },
      error => {
        this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
      }
    );
  }

  downloadOnboardingdetails() {
    this.blockDocument();
    this.applicationActivity('Download', 'On boarding - File' , ActionActivity['Onboarding'],this.quaryValues, this.clientInformationId);
    this.PricingQuotesMaster.GenerateOnboardingDetails(this.clientInformationId, this.priceQuoteId, "").subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('OnboardingDetails.pdf', data['url'], data['fileName']);
        }
        // else if(data['status'] == 'datanotavailable'){
        //   this.showMessage(data['message'], 'Data not available. Please fill in the data first.', 'warning')
        // }

        this.unblockDocument();
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  buttonclick() {
    this.downloadOnboardingdetails();
  }


  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;

  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }
}
