import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { I360Service } from 'src/Services/i360Service';
import { Params, ActivatedRoute } from '@angular/router';
import { BriefingChecklistModel } from 'src/Models/BriefingMasterModel';
import { UpdatesService } from 'src/Services/updatesService';
import { UsersService } from 'src/Services/users.service';
import { ToolEnum } from 'src/Models/Common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-briefing-checklist',
  templateUrl: './manage-briefing-checklist.component.html',
  styleUrls: ['./manage-briefing-checklist.component.css']
})
export class ManageBriefingchecklistComponent implements OnInit {
  briefingChecklistForm: FormGroup;
  loginuserID: number = 0;
  briefingChecklistId:number=0;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  BriefingChecklistModel: BriefingChecklistModel = {
    briefingChecklistId: 0,
    briefingId: '',
    checklistId:'',
    dueDate: '',
    isdone:false,
    remarks: '',
    createdBy:0
  };

  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  //Briefing Checklist MasterData dropdown
  dropdownListChecklist = [];
  selectedChecklist = [];
  dropdownSettingsChecklist = {};
  listOfChecklist = [];

  //Briefing Title MasterData List
  dropdownListBriefingTitle = [];
  selectedItemsBriefingTitle = [];
  dropdownSettingsBriefingTitle = {};
  listOfBriefingTitleIds = [];

  isDuplicate: boolean = false;
  isReadOnly:boolean=false;
  checklistname:string="";
  title:string="";

  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private i360service: I360Service,  
    private toastr: ToastrService,
    private datePipe: DatePipe) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Briefing Checklist');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.briefingChecklistForm = this._fb.group({
      briefingChecklistId: 0,
      briefingId: '',
      checkListId:'',
      dueDate: '',
      remarks: '',
      isDone:[false],
    });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
   
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.briefingChecklistId = Number(params['id']);
          this.isReadOnly=true;
          this.GetBriefingData();
        }
        else{
          this.isReadOnly=false;
        }
      } else{
        this.isReadOnly=false;
        this.GetBriefingTitleMasterData();
        this.GetBriefingChecklistMasterData(0);
      }
    });
  }

  GetBriefingChecklistMasterData(BriefingID:number): void {
    this.i360service.GetBriefingChecklistMasterData(this.tokenKey,BriefingID).subscribe(
      data => {
        if (data["masterData"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["masterData"].length; iCounter++) {
            this.dropdownListChecklist.push(
              { "id": data["masterData"][iCounter].checklistId, "itemName": data["masterData"][iCounter].checklistName }
            );
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsChecklist = {
          singleSelection: true,
          text: "Select Checklist(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 2,
          noDataLabel: 'No checklist available',
          showCheckbox: false,
          enableFilterSelectAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  onSelectChecklist($event) {
    this.listOfChecklist = this.removeElemetninArray(this.listOfChecklist, $event.id)
    this.listOfChecklist.push($event.id);
  }

  onDeSelectChecklist($event) {
    if (this.listOfChecklist != null) {
      if (this.listOfChecklist.length > 0) {
        this.listOfChecklist = this.removeElemetninArray(this.listOfChecklist, $event.id)
      }
    }
  }

  onSelectAllChecklist($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfChecklist != null) {
        if (this.listOfChecklist.length > 0) {
          this.listOfChecklist = this.removeElemetninArray(this.listOfChecklist, $event[iCounter].id)
          this.listOfChecklist.push($event[iCounter].id);
        }
        else {
          this.listOfChecklist.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllChecklist($event) {
    this.listOfChecklist = [];
  }

  GetBriefingTitleMasterData(): void {
    this.i360service.GetBriefingTitleMasterData(this.tokenKey).subscribe(
      data => {
        if (data["briefing"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["briefing"].length; iCounter++) {
            this.dropdownListBriefingTitle.push(
              { "id": data["briefing"][iCounter].briefingId, "itemName": data["briefing"][iCounter].title }
            );
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsBriefingTitle = {
          singleSelection: true,
          text: "Select Title(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 2,
          noDataLabel: 'No title available',
          showCheckbox: false,
          enableFilterSelectAll: false
        };

        let BriefingTitleId=sessionStorage.getItem("BriefingTitleId");
        if(BriefingTitleId!='' && BriefingTitleId!=null && BriefingTitleId!=undefined){
          if (this.dropdownListBriefingTitle.length > 0) {
            this.selectedItemsBriefingTitle = [];
            this.listOfBriefingTitleIds = [];
            let titledata = this.dropdownListBriefingTitle.filter(y => y.id == BriefingTitleId)[0];
            this.selectedItemsBriefingTitle.push({ "id": titledata.id, "itemName": titledata.itemName })
            this.listOfBriefingTitleIds.push(titledata.id)
          }

          sessionStorage.removeItem("BriefingTitleId")
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  onSelectBriefingTitle($event) {
    this.listOfBriefingTitleIds = this.removeElemetninArray(this.listOfBriefingTitleIds, $event.id)
    this.listOfBriefingTitleIds.push($event.id);
  }

  onDeSelectBriefingTitle($event) {
    if (this.listOfBriefingTitleIds != null) {
      if (this.listOfBriefingTitleIds.length > 0) {
        this.listOfBriefingTitleIds = this.removeElemetninArray(this.listOfBriefingTitleIds, $event.id)
      }
    }
  }

  onSelectAllBriefingTitle($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTitleIds != null) {
        if (this.listOfBriefingTitleIds.length > 0) {
          this.listOfBriefingTitleIds = this.removeElemetninArray(this.listOfBriefingTitleIds, $event[iCounter].id)
          this.listOfBriefingTitleIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingTitleIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllBriefingTitle($event) {
    this.listOfBriefingTitleIds = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  saveBriefing(briefingChecklistForm) {
    if (this.listOfChecklist.length == 0) {
      this.showMessage('Please select checklist', 'Checklist Required', 'error');
      return;
    }

    if (this.listOfBriefingTitleIds.length == 0) {
      this.showMessage('Please select briefing title', 'Briefing Title Required', 'error');
      return;
    }

    // if (this.briefingChecklistForm.value.dueDate == '' || typeof (this.briefingChecklistForm.value.dueDate) == 'undefined' || this.briefingChecklistForm.value.dueDate == null) {
    //   this.showMessage('Please enter due date', 'Due Date Required', 'error');
    //   return;
    // }
    this.BriefingChecklistModel.briefingChecklistId=this.briefingChecklistForm.value.briefingChecklistId;
    this.BriefingChecklistModel.briefingId=Number(this.listOfBriefingTitleIds);
    this.BriefingChecklistModel.checklistId= Number(this.listOfChecklist);
    this.BriefingChecklistModel.dueDate = this.datePipe.transform(this.briefingChecklistForm.value.dueDate, 'MM/dd/yyyy hh:mm:ss');
    this.BriefingChecklistModel.isdone=this.briefingChecklistForm.value.isDone;
    this.BriefingChecklistModel.remarks=this.briefingChecklistForm.value.remarks;
    this.BriefingChecklistModel.createdBy = this.loginuserID;

    this.i360service.BriefingChecklistAddUpdate(this.tokenKey, this.BriefingChecklistModel).subscribe(
      data => {
        if (data.status=="Success") {
          
            if (this.BriefingChecklistModel.briefingChecklistId == 0)
              this.showMessage('Briefing Checklist Inserted successfully!', 'Save', 'success');
            else
              this.showMessage('Briefing Checklist Updated successfully!', 'Update', 'success');           
        }
        else{
          if (this.BriefingChecklistModel.briefingChecklistId == 0){
            this.showMessage('Briefing Checklist Inserted fail!', 'Save', 'error');
          }
          else{
            this.showMessage('Briefing Checklist Updated fail!', 'Update', 'error');
          }
        }

        this.cancelBriefingServices();
      },
      error => {
       
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelBriefingServices() {
    let iscallPage=sessionStorage.getItem("callPage");
    if(iscallPage=="report"){
      this.router.navigate(['admin/briefingServices/briefingreport/checklist']);
      sessionStorage.removeItem("callPage");
    }
    else{
      this.router.navigate(['admin/briefingServices/checklist']);
    }
  }

  GetBriefingData(): void {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    
    this.i360service.GetBriefingChecklistById(this.tokenKey, this.briefingChecklistId).subscribe(
      data => {
        if (data != null) {
          this.BriefingChecklistModel = data["briefingData"];
          if (this.BriefingChecklistModel != null) {

            this.briefingChecklistForm.controls.briefingChecklistId.setValue(this.BriefingChecklistModel.briefingChecklistId);
            this.briefingChecklistForm.controls.dueDate.setValue(this.BriefingChecklistModel.dueDate);
            this.briefingChecklistForm.controls.isDone.setValue(this.BriefingChecklistModel.isdone);
            this.briefingChecklistForm.controls.remarks.setValue(this.BriefingChecklistModel.remarks);
            let iscallType=sessionStorage.getItem("callType");
            if(iscallType!="" && iscallType=="isDone" && (this.BriefingChecklistModel.dueDate=="" || this.BriefingChecklistModel.dueDate==null)){
              const dd = new Date();
              var dDate = [(dd.getMonth() + 1), (dd.getDate()), dd.getFullYear()].join('/');
              this.briefingChecklistForm.controls.dueDate.setValue(dDate);
              this.briefingChecklistForm.controls.isDone.setValue(true);
              sessionStorage.removeItem("callType");
            }

            if (!this.isReadOnly) {
              setTimeout(() => {
                if (this.dropdownListChecklist.length > 0) {
                  this.selectedChecklist = [];
                  this.listOfChecklist = [];
                  let checklistdata = this.dropdownListChecklist.filter(y => y.id == this.BriefingChecklistModel.checklistId)[0];
                  this.selectedChecklist.push({ "id": checklistdata.id, "itemName": checklistdata.itemName })
                  this.listOfChecklist.push(checklistdata.id)
                }

              }, 500);

              setTimeout(() => {
                if (this.dropdownListBriefingTitle.length > 0) {
                  this.selectedItemsBriefingTitle = [];
                  this.listOfBriefingTitleIds = [];
                  let titledata = this.dropdownListBriefingTitle.filter(y => y.id == this.BriefingChecklistModel.briefingId)[0];
                  this.selectedItemsBriefingTitle.push({ "id": titledata.id, "itemName": titledata.itemName })
                  this.listOfBriefingTitleIds.push(titledata.id)
                }
              }, 1000);
            }
            else {
              this.listOfChecklist.push(this.BriefingChecklistModel.checklistId);
              this.listOfBriefingTitleIds.push(this.BriefingChecklistModel.briefingId);
              this.checklistname = this.BriefingChecklistModel.checklistname;
              this.title = this.BriefingChecklistModel.title;
            }
          }

          this.isShowprogressSpinner = false;
          this.blockedDocument = false;
        }
      }, error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.showMessage(error, 'Error', 'error');
      })
  }
}