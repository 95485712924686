import { Component, HostListener, OnInit } from '@angular/core';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { BriefingChecklistReportFilterModel } from 'src/Models/filterModel';
import { I360Service } from 'src/Services/i360Service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-briefing-checklist-report',
  templateUrl: './briefing-checklist-report.component.html',
  styleUrls: ['./briefing-checklist-report.component.css']
})
export class BriefingchecklistReportComponent implements OnInit {
  filterModel = new BriefingChecklistReportFilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  // listOfBriefing: BriefingMasterModel[];
  listOfBriefing:any=[];
  columns: string[] = []; 
  tokenKey: string = '';
  SelectedTool: string;


  isloading: boolean;

  isShowprogressSpinner = false;
  loginuserID: number = 0;

  openMenuIndex: number = -1

  rangeDates: Date[] = [];
  fromDateFilter: string = '';
  toDateFilter: string = '';

  documentForm = new FormGroup({
    rangeDates: new FormControl(),
  });

  //Briefing Status MasterData List
  dropdownListStatus = [];
  selectedItemsStatus = [];
  dropdownSettingsStatus = {};
  listOfStatusIds = [];
  Checklist=[];

  constructor(private i360service: I360Service, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Briefing Checklist Report');
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 1000000;
    this.filterModel.orderBy = '';
    this.filterModel.IsDone=0;
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.GetBriefingChecklistMasterData();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.setCurrentMonthDateRange();
    this.GetStatusMasterData();
    //this.GetBriefingChecklistReport();
  }

  // GetBriefingChecklistReport() {
  //   this.isloading = true;
  //   this.listOfBriefing = [];
  //   this.i360service.GetBriefingChecklistReport(this.tokenKey, this.filterModel).subscribe(
  //     data => {
  //       this.isloading = false;
  //       if (data != null) {
  //         this.listOfBriefing = data['listOfChecklist'];
  //         if (this.listOfBriefing != null) {
  //           if (this.listOfBriefing.length > 0) {
  //             this.totalRecordCount = this.listOfBriefing[0].totalRecordCount;
  //           }
  //           else {
  //             this.totalRecordCount = 0;
  //           }
  //         }
  //         else {
  //           this.totalRecordCount = 0;
  //         }
  //       }
  //     },
  //     error => {
  //       this.isloading = false;
  //       console.log(error);
  //     }
  //   );
  // }

  cleanColumnName(column: string): string {
    return column.replace(/[^a-zA-Z0-9]/g, '');
  }

  capitalizeFirstLetter(column: string): string {
    return column.toUpperCase();
  }

  LowerCaseColumn(column: string): string {
    return column.toLowerCase();
  }

  GetBriefingChecklistMasterData(): void {
    this.i360service.GetBriefingChecklistMasterData(this.tokenKey,0).subscribe(
      data => {
        if (data["masterData"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["masterData"].length; iCounter++) {
            this.Checklist.push(
              { "id": data["masterData"][iCounter].checklistId, "itemName": data["masterData"][iCounter].checklistName }
            );
          }
        }
        else {
          this.toastr.error(data["message"]);
        }
      }, error => {
        this.toastr.error(error);
      })
  }

  GetBriefingChecklistReport() {
    this.isloading = true;
    this.listOfBriefing = [];
    this.i360service.GetBriefingChecklistDynamicReport(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data.isSuccess) {
          this.listOfBriefing = data.data;
          if (this.listOfBriefing != null) {
            if (this.listOfBriefing.length > 0) {
              this.totalRecordCount = this.listOfBriefing[0].totalRecordCount;

              if (this.listOfBriefing.length) {
                this.columns = Object.keys(this.listOfBriefing[0]).filter(
                  (key) => !['updateDate', 'title','totalRecordCount','briefingId'].includes(key) && // Exclude static fields
                  !key.endsWith('IsDone') && // Remove 'IsDone' suffix columns
                  !key.endsWith('BCID') && // Remove 'BCID' suffix columns
                  !key.endsWith('bcid') && // Remove 'BCID' suffix columns
                  !key.endsWith('Remarks') // Remove 'Remarks' suffix columns
                );

                let columnsDublicate=[]
                columnsDublicate=this.columns
                this.columns=[];
                if (this.Checklist.length > 0) {
                  for (let i = 0; i < this.Checklist.length; i++) {
                    if (columnsDublicate.length > 0) {
                      for (let s = 0; s < columnsDublicate.length; s++) {
                        if (this.cleanColumnName(this.LowerCaseColumn(columnsDublicate[s])) == this.cleanColumnName(this.LowerCaseColumn(this.Checklist[i].itemName)))
                          this.columns.push(columnsDublicate[s]);
                      }
                    }
                  }
                }
              }
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.search = $event.target.value;
    }
    else {
      this.filterModel.search = '';
    }
    this.GetBriefingChecklistReport();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page);
    this.GetBriefingChecklistReport();
  }

  isDateFilter(dateArray: any) {
    this.rangeDates = dateArray;

    if (this.rangeDates != null) {
      if (this.rangeDates.length > 1) {
        let dd1 = this.rangeDates[0].getDate().toString();
        if (parseInt(dd1.toString()) < 10)
          dd1 = '0' + dd1.toString();

        let mm1 = (this.rangeDates[0].getMonth() + 1).toString();
        if (parseInt(mm1.toString()) < 10)
          mm1 = '0' + mm1.toString();

        let dd2 = this.rangeDates[1].getDate().toString();
        if (parseInt(dd2.toString()) < 10)
          dd2 = '0' + dd2.toString();

        let mm2 = (this.rangeDates[1].getMonth() + 1).toString();
        if (parseInt(mm2.toString()) < 10)
          mm2 = '0' + mm2.toString();

        this.fromDateFilter = this.rangeDates[0].getFullYear() + mm1 + dd1;
        this.toDateFilter = this.rangeDates[1].getFullYear() + mm2 + dd2;
      }
      if (this.fromDateFilter === null || this.fromDateFilter === '') {
        this.fromDateFilter = "";
        this.toDateFilter = "";
      }
    }
    else {
      this.fromDateFilter = "";
      this.toDateFilter = "";
    }
    if (this.fromDateFilter != '' && this.toDateFilter != '') {
      this.filterModel.fromDate = this.fromDateFilter;
      this.filterModel.toDate = this.toDateFilter;
      this.GetBriefingChecklistReport();
    }

  }

  dateFilterClear() {
    this.rangeDates = [];
    this.fromDateFilter = null;
    this.toDateFilter = null;
    this.filterModel.fromDate = null;
    this.filterModel.toDate = null;    
    this.GetBriefingChecklistReport();
  }

  setCurrentMonthDateRange() {
    const startOfMonth = moment().startOf('month').toDate();
    const endOfMonth = moment().endOf('month').toDate();
    this.rangeDates = [startOfMonth, endOfMonth];
  }

  exportToExcel() {
    if (this.listOfBriefing.length > 0) {
      var uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
        , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
        , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
      let html = '<table>';
      // if (this.filterModel.companyId && this.selectedCompany) {
      //   html += "</tr><tr class='mainhead'>    <td colspan='3'> Company: " + this.selectedCompany[0].companyName + "</td>  </tr>";
      // }
      html += document.getElementById("reportHeader").innerHTML + " " + document.getElementById("reportBody").innerHTML;
      html += "</table>";
      var ctx = { worksheet: 'Worksheet', table: html }
      let dt = this.transformDate(new Date());
      FileSaver.saveAs((uri + base64(format(template, ctx))), "BriefingChecklistReportExport_" + dt + ".xls");
    }
    else {
      this.toastr.error("No record found", "error");
    }
  }

  transformDate(array) {
    if (array != null) {

      let dd = array.getDate().toString();
      if (parseInt(dd.toString()) < 10)
        dd = '0' + dd.toString();

      let mm = (array.getMonth() + 1).toString();
      if (parseInt(mm.toString()) < 10)
        mm = '0' + mm.toString();

      let hrs = array.getHours();
      if (parseInt(hrs.toString()) < 10)
        hrs = '0' + hrs.toString();

      let min = array.getMinutes()
      if (parseInt(min.toString()) < 10)
        min = '0' + min.toString();

      let sec = array.getSeconds()
      if (parseInt(sec.toString()) < 10)
        sec = '0' + sec.toString();

      return array.getFullYear() + '_' + mm + '_' + dd + '_' + hrs + '_' + min + '_' + sec;
    }
  }

  editData(ids,type) {
    if(type==2){
      sessionStorage.setItem("callType","isDone");
      sessionStorage.setItem("callPage","report");
    }
    else{
      sessionStorage.removeItem("callType");
      sessionStorage.removeItem("callPage");
    }

    this.router.navigate(['admin/briefingServices/manage-checklist/' + ids])
  }

  GetStatusMasterData(): void {
    this.dropdownListStatus.push(
      { "id": 1, "itemName": "✔ Done" },
      { "id": 2, "itemName": "! Needs Review" }
    );

    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Select Status(es)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No status available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  onSelectStatus($event) {
    this.listOfStatusIds = this.removeElemetninArray(this.listOfStatusIds, $event.id)
    this.listOfStatusIds.push($event.id);
    this.filterModel.IsDone=Number($event.id);
    this.GetBriefingChecklistReport();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatusIds != null) {
      if (this.listOfStatusIds.length > 0) {
        this.listOfStatusIds = this.removeElemetninArray(this.listOfStatusIds, $event.id)
      }
    }
    this.filterModel.IsDone=0;
    this.GetBriefingChecklistReport();
  }

  onSelectAllStatus($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStatusIds != null) {
        if (this.listOfStatusIds.length > 0) {
          this.listOfStatusIds = this.removeElemetninArray(this.listOfStatusIds, $event[iCounter].id)
          this.listOfStatusIds.push($event[iCounter].id);
        }
        else {
          this.listOfStatusIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllStatus($event) {
    this.listOfStatusIds = [];
    this.filterModel.IsDone=0;
    this.GetBriefingChecklistReport();
  }
}
